<template>
  <div>
    <div class="floating-loading" v-if="getDataLoading">
      <div
        class="d-flex"
        style="
          border: 1px solid #e8e9eb;
          width: 120px;
          padding: 10px;
          border-radius: 10px;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 20px"
            class="mr-3 mb-0"
            spin
          />
        </a-spin>
        Loading...
      </div>
    </div>
    <div class="d-flex row justify-content-between align-items-center mb-2">
      <a-range-picker
        format="YYYY-MM-DD"
        style="width: 16%"
        @change="onDateChange"
      />

      <a-select
        v-model="statusQuery"
        style="width: 100%"
        class="col-xl-2"
        @change="onStatusChange"
      >
        <a-select-option value="">Semua</a-select-option>
        <!-- <a-select-option value="Menunggu Perawat"
          >Menunggu Perawat</a-select-option
        >
        <a-select-option value="Menunggu Dokter"
          >Menunggu Dokter</a-select-option
        >
        <a-select-option value="Diperiksa Dokter"
          >Diperiksa Dokter</a-select-option
        > -->
        <a-select-option value="Selesai Verifikasi Kode"
          >Verifikasi Berkas</a-select-option
        >
        <a-select-option value="Selesai Verifikasi Berkas"
          >Selesai Verif Berkas</a-select-option
        >
      </a-select>
      <a-select
        v-model="specializationQuery"
        style="width: 100%"
        class="col-xl-2"
        placeholder="Please select"
        :options="filteredSpecializations"
        show-search
        :filter-option="false"
        @search="handleSearch"
      />
      <a-input
        placeholder="Ketik untuk mencari nama pasien..."
        @change="onSearchChange"
        style="width: 100%"
        class="col-xl-6"
      >
        <template #prefix>
          <a-icon type="search" />
        </template>
      </a-input>
    </div>

    <div>
      <a-tabs>
        <a-tab-pane key="1" tab="Rawat Jalan">
          <div style="overflow-x: auto">
            <a-table
              :columns="columns"
              :dataSource="filteredData"
              @rowClick="onRowRawatJalanClick"
              :rowKey="(record) => record.id"
            >
              <template slot="status" slot-scope="text, record">
                <div class="d-flex align-items-center">
                  <div
                    :style="{
                      backgroundColor: getStatusBackgroundColor(
                        text === 'Selesai Verifikasi Berkas'
                          ? 'Selesai Verif Berkas'
                          : 'Verifikasi Berkas',
                      ),
                      color: getStatusColor(
                        text === 'Selesai Verifikasi Berkas'
                          ? 'Selesai Verif Berkas'
                          : 'Verifikasi Berkas',
                      ),
                      borderRadius: '999px',
                      textAlign: 'center',
                      width: '132px',
                      padding: '2px 8px 2px 8px',
                    }"
                  >
                    {{
                      text === "Selesai Verifikasi Berkas"
                        ? "Selesai Verif Berkas"
                        : "Verifikasi Berkas"
                    }}

                    <!-- {{ text }} -->
                  </div>
                  <div
                    v-if="
                      text !== 'Selesai Verifikasi Berkas' &&
                      record.KlarifikasiMasters.length
                    "
                    class="ml-2"
                    :style="{
                      backgroundColor: getStatusBackgroundColor(
                        record.KlarifikasiMasters[0].KlarifikasiChats.sender ===
                          userId
                          ? 'Wait'
                          : 'New',
                      ),
                      color: getStatusColor(
                        record.KlarifikasiMasters[0].KlarifikasiChats.sender ===
                          userId
                          ? 'Wait'
                          : 'New',
                      ),
                      borderRadius: '999px',
                      textAlign: 'center',
                      width: '52px',
                      padding: '2px 8px 2px 8px',
                    }"
                  >
                    {{
                      record.KlarifikasiMasters[0].KlarifikasiChats.sender ===
                      userId
                        ? "Wait"
                        : "New"
                    }}
                  </div>
                </div>
              </template>
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Rawat Inap" disabled>
          <!-- rawat inap -->
        </a-tab-pane>
        <a-tab-pane key="3" tab="IGD" disabled>
          <!-- IGD -->
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { getVisitFilter } from "@/services/axios/api/kunjungan"
import { getAllVisitClarification } from "@/services/axios/api/fileReview"
import { getAllSpecializations } from "@/services/axios/api/dokter"

import moment from "moment"

export default {
  data() {
    return {
      date: null,
      dateQuery: "",
      statusQuery: "",
      patientNameQuery: "",
      sortByQuery: "arrive_time.asc",
      includeDoctorDetail: true,
      includeAnamnesisDetail: true,
      doctor_id: "",
      // filteredData: [],
      realData: [],
      getDataLoading: false,
      columns: [
        {
          title: "No.",
          key: "no",
          customRender: (text, record, index) => index + 1,
        },
        {
          title: "No. Kunjungan",
          dataIndex: "noKunjungan",
          key: "medicalRecordNo",
        },
        {
          title: "No. SEP",
          dataIndex: "noKunjungan",
          key: "kunjunganNo",
        },
        {
          title: "Nama Pasien",
          dataIndex: "Patient.name",
          key: "patientName",
        },
        {
          title: "Spesialisasi",
          dataIndex: "Doctor.Specialization.name",
          key: "specializationName",
          sorter: (a, b) =>
            a.Doctor.Specialization.name.length -
            b.Doctor.Specialization.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "DPJP",
          dataIndex: "Doctor.User.name",
          key: "doctorName",
          sorter: (a, b) =>
            a.Doctor.User.name.length - b.Doctor.User.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tanggal",
          dataIndex: "created_date",
          key: "createdDate",
          sorter: (a, b) => a.created_date.length - b.created_date.length,
          sortDirections: ["descend", "ascend"],
        },
        // {
        //   title: 'Jam Datang',
        //   dataIndex: 'created_time',
        //   key: 'createdTime',
        //   sorter: (a, b) => {
        //     const timeA = a.created_time.split(':').map(Number)
        //     const timeB = b.created_time.split(':').map(Number)
        //     const secondsA = timeA[0] * 3600 + timeA[1] * 60 + timeA[2]
        //     const secondsB = timeB[0] * 3600 + timeB[1] * 60 + timeB[2]
        //     return secondsA - secondsB
        //   },
        // },
        // {
        //   title: 'Kategori',
        //   dataIndex: 'Patient.payment_method',
        //   key: 'paymentMethod',
        // },
        // {
        //   title: 'Tanggal Lahir',
        //   dataIndex: 'birth_date',
        //   key: 'birthDate',
        // },
        // {
        //   title: 'Keluhan Utama',
        //   dataIndex: 'keluhan_utama',
        //   key: 'anamnesis',
        // },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
      ],
      filteredSpecializations: [],
      specializationQuery: "",
      startDate: null,
      endDate: null,
      searchQuery: "",
      userId: "",
    }
  },
  created() {
    // const today = new Date().toISOString().split('T')[0]
    // this.dateQuery = today
    // console.log(this.dateQuery, "tanggal hari ini");

    this.doctor_id = window.localStorage.getItem("id")
  },
  methods: {
    onChange(date, dateString) {
      this.dateQuery = dateString
      console.log(date, dateString)
    },
    onRowRawatJalanClick(record) {
      console.log(record, "record")
      // this.$router.push(`/pemeriksaan/dokter/periksa/${record.id}`);
      // if (record.status === 'Menunggu Perawat') {
      // } else if (
      //   record.status === 'Menunggu Dokter' ||
      //   record.status === 'Diperiksa Dokter'
      // ) {
      this.$router.push(`/pemeriksaan-berkas/detail/${record.id}`)
      // } else {
      //   this.$router.push(`/pemeriksaan/detail/${record.id}`)
      // }
    },

    async getVisit() {
      this.getDataLoading = true
      const role = window.localStorage.getItem("role")
      const userId = window.localStorage.getItem("id")
      this.userId = userId
      // console.log(role, 'inirole')
      let response
      if (role === "fileReviewer") {
        response = await getVisitFilter(
          this.dateQuery,
          this.statusQuery,
          this.patientNameQuery,
          this.sortByQuery,
          this.includeDoctorDetail,
          this.includeAnamnesisDetail,
          (this.doctor_id = ""),
        )
      } else {
        // if (role === 'koder') {
        //   this.statusQuery = 'Selesai Verifikasi Kode'
        // }
        // console.log("masuk sini kan?")
        response = await getAllVisitClarification(userId)
        // console.log(response.data.visit, 'iniberkasdoktersisi')
      }
      this.statusQuery = "Selesai Verifikasi Kode"

      //   console.log(response.data.visits, 'data')
      if (response) {
        this.filteredData = response.data.visits.map((visit) => {
          let keluhan = ""
          if (visit.Anamnesis === null) {
            keluhan = "-"
          } else {
            for (const keluhanUtama in visit.Anamnesis.ChiefComplaints) {
              if (visit.Anamnesis.ChiefComplaints.length === 1) {
                keluhan +=
                  visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan
              } else {
                if (keluhanUtama > visit.Anamnesis.ChiefComplaints.length - 2) {
                  keluhan +=
                    visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan
                } else {
                  keluhan +=
                    visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan +
                    ", "
                }
              }
            }
          }
          // let noKunjungan = 'RJ'
          // console.log(visit, 'inipervisit')
          // console.log(visit.arrive_time, 'ini arrivetime')
          // console.log(visit.Patient.medical_record_no, 'ini medcord')
          // console.log(visit.Doctor, 'ini doctor id spesialisasi')
          let noKunjungan = "RJ"

          // Format tanggal visit (6 digit): DDMMYY
          const visitDate = new Date(visit.arrive_time)
          const formattedDate = visitDate
            .toISOString()
            .slice(0, 10) // Ambil format YYYY-MM-DD
            .split("-") // Pisahkan berdasarkan tanda -
            .reverse() // Balikkan urutannya ke [DD, MM, YY]
            .map((part, index) => (index === 2 ? part.slice(-2) : part)) // Ambil 2 digit terakhir tahun
            .join("") // Gabungkan tanpa separator

          // Ambil 2 digit jam visit
          const visitHour = visitDate.getUTCHours().toString().padStart(2, "0")

          // Ambil 4 digit belakang nomor rekam medis pasien
          const medicalRecordLast4 = visit.Patient.medical_record_no.slice(-3)

          // ID spesialisasi dokter (2 digit)
          const specializationId = visit.Doctor.Specialization.id
            .toString()
            .padStart(2, "0")

          // Gabungkan format ke nomor kunjungan
          noKunjungan += `${formattedDate}${visitHour}${medicalRecordLast4}${specializationId}`
          // console.log(visit)
          if (visit.KlarifikasiMasters.length) {
            visit.KlarifikasiMasters = visit.KlarifikasiMasters.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            )
            const latestChat =
              visit.KlarifikasiMasters[0].KlarifikasiChats.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
              )[0]
            // console.log("masihada?")
            visit.KlarifikasiMasters[0].KlarifikasiChats = latestChat
            // console.log(noKunjungan)
          }

          return {
            ...visit,
            birth_date: this.formatDate(visit.Patient.birth_date),
            created_date: this.formatDate(visit.createdAt),
            created_time: this.formatTime(visit.createdAt).replace(/\./g, ":"),
            Anamnesis: visit.Anamnesis ? visit.Anamnesis : "-",
            keluhan_utama: keluhan,
            noKunjungan,
          }
        })

        this.realData = response.data.visits.map((visit) => {
          let keluhan = ""
          if (visit.Anamnesis === null) {
            keluhan = "-"
          } else {
            for (const keluhanUtama in visit.Anamnesis.ChiefComplaints) {
              if (visit.Anamnesis.ChiefComplaints.length === 1) {
                keluhan +=
                  visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan
              } else {
                if (keluhanUtama > visit.Anamnesis.ChiefComplaints.length - 2) {
                  keluhan +=
                    visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan
                } else {
                  keluhan +=
                    visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan +
                    ", "
                }
              }
            }
          }
          // let noKunjungan = 'RJ'
          // console.log(visit, 'inipervisit')
          console.log(visit.arrive_time, "ini arrivetime")
          console.log(visit.Patient.medical_record_no, "ini medcord")
          console.log(
            visit.Doctor.Specialization.id,
            "ini doctor id spesialisasi",
          )
          let noKunjungan = "RJ"

          // Format tanggal visit (6 digit): DDMMYY
          const visitDate = new Date(visit.arrive_time)
          const formattedDate = visitDate
            .toISOString()
            .slice(0, 10) // Ambil format YYYY-MM-DD
            .split("-") // Pisahkan berdasarkan tanda -
            .reverse() // Balikkan urutannya ke [DD, MM, YY]
            .map((part, index) => (index === 2 ? part.slice(-2) : part)) // Ambil 2 digit terakhir tahun
            .join("") // Gabungkan tanpa separator

          // Ambil 2 digit jam visit
          const visitHour = visitDate.getUTCHours().toString().padStart(2, "0")

          // Ambil 4 digit belakang nomor rekam medis pasien
          const medicalRecordLast4 = visit.Patient.medical_record_no.slice(-3)

          // ID spesialisasi dokter (2 digit)
          const specializationId = visit.Doctor.Specialization.id
            .toString()
            .padStart(2, "0")

          // Gabungkan format ke nomor kunjungan
          noKunjungan += `${formattedDate}${visitHour}${medicalRecordLast4}${specializationId}`

          console.log(noKunjungan)

          return {
            ...visit,
            birth_date: this.formatDate(visit.Patient.birth_date),
            created_date: this.formatDate(visit.createdAt),
            created_time: this.formatTime(visit.createdAt).replace(/\./g, ":"),
            Anamnesis: visit.Anamnesis ? visit.Anamnesis : "-",
            keluhan_utama: keluhan,
            noKunjungan,
          }
        })
        if (this.statusQuery) {
          this.filteredData = this.realData.filter(
            (element) => element.status === this.statusQuery,
          )
        }
        // console.log(this.filteredData, 'inifiltered')
      }
      const { specializations: dataSpesializations } =
        await getAllSpecializations()
      this.specializations = dataSpesializations.map((e) => {
        return {
          value: e.id,
          label: e.name,
        }
      })
      this.filteredSpecializations = dataSpesializations.map((e) => {
        return {
          value: e.id,
          label: e.name,
        }
      })
      this.filteredSpecializations.unshift({
        value: "",
        label: "Semua",
      })
      this.filteredSpecializations.unshift({
        value: "",
        label: "Cari Spesialisasi...?",
        disabled: true,
      })

      this.getDataLoading = false
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      const date = new Date(dateString)
      return date.toLocaleString("id-ID", options)
    },

    formatTime(dateString) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }
      const date = new Date(dateString)
      return date.toLocaleTimeString("id-ID", options)
    },
    handleSearch(value) {
      // Update filteredSpecializations based on the search term
      this.filteredSpecializations = this.specializations.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase()),
      )
    },
    // handleFilterSpecialization() {
    //   this.specializationQuery = this.specializationQuery
    // },
    onDateChange(dateRange) {
      // Format startDate dan endDate
      const [startDate, endDate] = dateRange.map((date) =>
        moment(date).format("YYYY-MM-DD"),
      )
      this.startDate = startDate
      this.endDate = endDate
      // console.log('Start:', startDate, 'End:', endDate)
      // console.log(this.filteredData)

      // this.filteredData = this.realData.filter((data) => {
      //   const createdAtDate = moment(data.createdAt).format('YYYY-MM-DD') // Format createdAt ke 'YYYY-MM-DD'
      //   return createdAtDate >= startDate && createdAtDate <= endDate // Periksa apakah berada dalam rentang
      // })
    },
    onSearchChange(e) {
      this.searchQuery = e.target.value.toLowerCase()
    },
    onStatusChange(e) {
      this.statusQuery = e
    },
    getStatusColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#875BF7",
        "Balasan Baru": "#EF6820",
        Wait: "#2E90FA",
        "Selesai Verif Berkas": "#16B364",
        "Verifikasi Berkas": "#EF6820",
        New: "#F79009",
      }
      return statusColorMap[status]
    },
    getStatusBackgroundColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#F3F1FF",
        "Balasan Baru": "#FFF2E4",
        "Diperiksa Dokter": "#E1F1FF",
        "Selesai Verif Berkas": "#E1FBEA",
        "Verifikasi Berkas": "#FFF2E4",
        Wait: "#E1F1FF",
        New: "#FFF6DB",
      }
      return statusColorMap[status]
    },
  },
  async mounted() {
    this.getVisit()
  },
  computed: {
    filteredData() {
      return this.realData.filter((data) => {
        let isValid = true
        // Filter berdasarkan tanggal
        if (this.startDate && this.endDate) {
          const createdAtDate = moment(data.createdAt).format("YYYY-MM-DD")
          isValid =
            isValid &&
            createdAtDate >= this.startDate &&
            createdAtDate <= this.endDate
        }

        // Filter berdasarkan status
        if (this.statusQuery) {
          isValid = isValid && data.status === this.statusQuery
        }

        // Filter berdasarkan spesialisasi
        if (this.specializationQuery) {
          isValid =
            isValid &&
            data.Doctor.Specialization.id === this.specializationQuery
        }

        // Filter berdasarkan pencarian nama pasien
        if (this.searchQuery) {
          isValid =
            isValid &&
            data.Patient.name.toLowerCase().includes(this.searchQuery)
        }

        return isValid
      })
    },
  },
}
</script>

<!-- <style lang="scss" module>
@import './style.module.scss';
</style> -->

<style scoped>
.floating-loading {
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* for mobile */
  @media (max-width: 768px) {
    top: 65px;
  }
}
</style>
