<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="">
          <div class="card-body">
            <div class="row" style="background-color: #f3fdff">
              <div class="col-3 container">
                <img
                  src="resources/images/medisync-big.svg"
                  alt="Medisync"
                  height="50px"
                />
              </div>
              <div class="col-6 header-text container">
                Daftar
                {{
                  role === 'fileReviewer' ? 'Pemeriksaan' : 'Klarifikasi'
                }}
                Berkas Klaim BPJS
              </div>
              <div class="col-3 container">
                {{ this.name }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <dashboard-berkas />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardBerkas from '@/components/tables/dashboard-berkas'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  components: {
    dashboardBerkas,
  },
  data() {
    return {
      name: null,
      labelCol,
      wrapperCol,
      role: '',
    }
  },
  created() {
    this.getName()
  },
  methods: {
    getName() {
      const name = localStorage.getItem('name')
      const role = localStorage.getItem('role')
      this.name = name
      this.role = role
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
