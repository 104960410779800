import apiClient from '@/services/axios'

export const getAllClarification = async (idKunjungan, userId) => {
  const res = await apiClient.get(`/api/file-review/`, {
    params: {
      user: userId,
      idKunjungan,
    },
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const getCodeLogs = async (idKunjungan) => {
  const res = await apiClient.get(`/api/file-review/logs`, {
    params: {
      idKunjungan,
    },
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const updateSoapOrCode = async ({
  idKunjungan,
  subjective,
  oldSubjective,
  oldObjective,
  objective,
  oldPlan,
  plan,
  oldAssessment,
  assessment,
  primaryCondition,
  oldPrimaryCondition,
  secondaryConditions,
  oldSecondaryConditions,
  procedures,
  oldProcedures,
  type,
}) => {
  const res = await apiClient.post(`/api/file-review/logs`, {
    type,
    idKunjungan,
    subjective,
    oldSubjective,
    oldObjective,
    objective,
    oldPlan,
    plan,
    oldAssessment,
    assessment,
    primary_condition: primaryCondition,
    old_primary_condition: oldPrimaryCondition,
    secondary_conditions: secondaryConditions,
    old_secondary_conditions: oldSecondaryConditions,
    procedures,
    old_procedures: oldProcedures,
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const getAllVisitClarification = async (userId) => {
  const res = await apiClient.get(`/api/file-review/visit`, {
    params: {
      userId,
    },
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const getAllMessages = async (idKunjungan) => {
  const res = await apiClient.get(`/api/file-review/chats/` + idKunjungan)
  if (res) {
    return res
  } else {
    return null
  }
}

export const createClarification = async ({
  sender,
  receiver,
  subject,
  comment,
  idKunjungan,
  name,
}) => {
  const res = await apiClient.post(`/api/file-review`, {
    sender,
    receiver,
    subject,
    comment,
    idKunjungan,
    name,
  })
  if (res) {
    return res
  } else {
    return null
  }
}

export const finishClarification = async (id) => {
  const res = await apiClient.put(`/api/file-review/finish`, {
    id,
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const finishBerkas = async (id) => {
  const res = await apiClient.put(`/api/file-review/finish-berkas`, {
    id,
  })
  if (res) {
    return res
  } else {
    return null
  }
}

export const getPatientVisit = async (patientId) => {
  const res = await apiClient.get(`/api/file-review/${patientId}`, {})
  if (res) {
    return res
  } else {
    return null
  }
}
